<template>
    <div class="dialog-container">
        <el-dialog
            title="会员卡详情"
            :visible.sync="showFlag"
            :before-close="closeDialog"
            center
            :close-on-click-modal="false"
            :append-to-body="true"
            top="3vh"
            width="1000px"
            >
            <el-form ref="form" class="detail-form other" label-width="120px">
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="会员卡名称:">
                            <div class="t-item">{{ form.name }}</div>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="会员卡类型:">
                            <div class="t-item">{{ form.cardTypeText }}</div>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="会员卡号:">
                            <div class="t-item">{{ form.no }}</div>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="会员卡状态:">
                            <div class="t-item">
                                <span class="t-status"
                                :class="{'status1': form.state === 1, 'status2': form.state === 2, 'status3': form.state === 3,'status4': form.state === 4}">
                                    <span>{{ form.stateText }}</span>
                                </span>
                            </div>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="发行商户:">
                            <div class="t-item">{{ valueToText(form.merchantId, merchantBox, 'merchantId', 'merchantName') }}</div>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="实体卡号:">
                            <div class="t-item">{{ form.physicalNo }}</div>
                        </el-form-item>
                    </el-col>
                </el-row>
                <div class="line"></div>
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="购卡时间:">
                            <div class="t-item">{{ form.buyTime }}</div>
                        </el-form-item>
                    </el-col>
                    <el-col v-if="form.cardType === 1" :span="8">
                        <el-form-item label="购买天数:">
                            <div class="t-item">{{ form.termDays }}天</div>
                        </el-form-item>
                    </el-col>
                    <el-col v-if="form.cardType !== 3" :span="8">
                        <el-form-item label="赠送天数:">
                            <div class="t-item">{{ form.giveDayQty || 0 }}天</div>
                        </el-form-item>
                    </el-col>
                    <el-col v-if="form.cardType !== 3" :span="8">
                        <el-form-item label="剩余天数:">
                            <div class="t-item">{{ form.availableDayQty + form.giveDayQty || 0 }}天</div>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="到期日期:">
                            <div class="t-item">{{ form.expireTime }}</div>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="激活日期:">
                            <div class="t-item">{{ form.activeTime }}</div>
                        </el-form-item>
                    </el-col>
                    <el-col v-if="form.cardType === 2" :span="8">
                        <el-form-item label="购买次数:">
                            <div class="t-item">{{ form.useLimitQty }}次</div>
                        </el-form-item>
                    </el-col>
                    <el-col v-if="form.cardType === 2" :span="8">
                        <el-form-item label="赠送次数:">
                            <div class="t-item">{{ form.giveTicketQty }}次</div>
                        </el-form-item>
                    </el-col>
                    <el-col v-if="form.cardType === 2" :span="8">
                        <el-form-item label="剩余次数:">
                            <div class="t-item">{{ form.availableTicketQty + form.giveTicketQty }}次</div>
                        </el-form-item>
                    </el-col>
                    <el-col v-if="form.cardType === 3" :span="8">
                        <el-form-item label="充值金额:">
                            <div class="t-item">{{ form.availableMoney || 0 }}元</div>
                        </el-form-item>
                    </el-col>
                    <el-col v-if="form.cardType === 3" :span="8">
                        <el-form-item label="赠送金额:">
                            <div class="t-item">{{ form.giveMoney || 0 }}元</div>
                        </el-form-item>
                    </el-col>
                    <el-col v-if="form.cardType === 3" :span="8">
                        <el-form-item label="金额可用业务:">
                            <div class="t-item">{{ form.availableToText }}</div>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="是否可退卡:">
                            <div class="t-item">{{ form.availableExitMarkText }}</div>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="是否可转卡:">
                            <div class="t-item">{{ form.transferLimitText }}</div>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="是否可共享:">
                            <div class="t-item">{{ form.shareLimitText }}</div>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8" v-if="form.isTransfer">
                        <el-form-item label="是否可多次转卡:">
                            <div class="t-item">{{ form.transferMoreLimitText }}</div>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="是否可停卡:">
                            <div class="t-item">{{ form.pauseLimitText }}</div>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8" v-if="form.pauseLimitQty">
                        <el-form-item label="可停卡次数:">
                            <div class="t-item">{{ form.pauseLimitQty }}次</div>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8" v-if="form.pauseLimitQty">
                        <el-form-item label="每次停卡天数:">
                            <div class="t-item">{{ form.pauseDayLimitQty }}天</div>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8" v-if="form.pauseLimitQty">
                        <el-form-item label="已停卡次数:">
                            <!-- <div class="df ai" style="padding-top: 0px; color:#333333; font-weight:bold;"> -->
                                <div class="t-item">{{ form.stoppedQty }}次</div>
                                <!-- <div v-if="form.stoppedQty > 0" class="margin-left-10">
                                    <el-button type="text">查看停卡记录</el-button>
                                </div> -->
                            <!-- </div> -->
                        </el-form-item>
                    </el-col>
                    <el-col :span="8" v-if="form.shareLimitQty">
                        <el-form-item label="最大共享人数:">
                            <div class="t-item">{{ form.shareLimitQty }}人</div>
                        </el-form-item>
                    </el-col>
                    <el-col v-if="form.shareLimitQty > 0" :span="24">
                        <el-form-item label="共享人:">
                            <div class="t-item">{{ form.shareNameListText }}</div>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item label="可通行区域:">
                            <div class="t-item">
                                <span v-for="(item, index) in form.venueDef" :key="index"><span v-if="index > 0">,</span>{{ formatterVenue(item) }}</span>
                            </div>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item label="时段限制:">
                            <div class="t-item">
                                <span v-for="(item, index) in form.periodDef" :key="index">
                                    <el-divider v-if="index > 0" direction="vertical"></el-divider>
                                    <span>{{ getweek(item.day) }} </span>
                                    <span>{{ item.start }} ~ {{ item.end }}</span>
                                </span>
                                <!-- <span>周一、周二、周三、周四、周五  08：00~22：00</span>
                                <el-divider direction="vertical"></el-divider>
                                <span>周六、周日  08：00~18：00</span> -->
                            </div>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <template slot="footer">
                <el-button class="footer-confirm" @click="closeDialog">返回</el-button>
            </template>
        </el-dialog>
    </div>
</template>

<script>
import apis from "@/apis";
import dictList from "@/utils/dictList.js";
import { changeInfo } from "@/utils/index";

export default {
    props: {
        venueBox: {
            required: true,
            type: Array,
            default: () => {
                return []
            },
        },
        merchantBox: {
            required: true,
            type: Array,
            default: () => {
                return []
            },
        },
    },
    data() {
        return {
            showFlag: false,
            form: {},
            cardTypeList: dictList.CARD_TYPE || [],
            cardTermList: dictList.CARD_TERM || {},
            activeTypeList: dictList.ACTIVE_TYPE || [],
            yesNoList: dictList.YES_NO || [],
            cardStateList: dictList.USER_CARD_STATE || [],
            availabelBusinessList: dictList.AVAILABEL_BUSINESS_TYPE || [],
            week: [
                { value: "1", text: "周一" },
                { value: "2", text: "周二" },
                { value: "3", text: "周三" },
                { value: "4", text: "周四" },
                { value: "5", text: "周五" },
                { value: "6", text: "周六" },
                { value: "7", text: "周日" },
            ],
        }
    },
    methods: {
        // 弹框打开触发
        showDialog(id) {
            this.form = {}
            this.showFlag = true
            this.getDetails(id)
        },
        valueToText(value, list, keyValue, keyText) {
            return changeInfo(value, list, keyValue, keyText)
        },
        formatterVenue(data) {
            console.log(data, this.venueBox, 777)
            let returnData = ''
            if (this.venueBox.length > 0) {
                this.venueBox.forEach((item) => {
                    if (item.id === data) {
                        returnData = item.venueName
                    }
                })
            }
            return returnData
        },
        // 获取星期方法
        getweek(data) {
            const arr = []
            this.week.forEach((item) => {
                data.forEach((el) => {
                    if (el === item.value - 0 || el === item.value) {
                        arr.push(item.text)
                    }
                })
            })
            return arr.join(',')
        },
        // 获取会员会员卡详情
        getDetails(id) {
            this.$http.get(`${apis.getDetailByCardId}?cardId=${id}`).then((res) => {
                if (res.data.code === 0) {
                    this.form = {
                        ...res.data.data,
                    }
                    this.form.cardTypeText = changeInfo(this.form.cardType, this.cardTypeList, 'value', 'label')
                    this.form.stateText = changeInfo(this.form.state, this.cardStateList, 'value', 'label')
                    this.form.activeTypeText = changeInfo(this.form.activeType, this.activeTypeList, 'value', 'label')
                    this.form.availableExitMarkText = changeInfo(this.form.availableExitMark, this.yesNoList, 'value', 'label')
                    this.form.shareLimitText = this.form.shareLimitQty ? '是' : '否'
                    this.form.pauseLimitText = this.form.pauseLimitQty ? '是' : '否'
                    this.form.transferLimitText = this.form.isTransfer ? '是' : '否'
                    this.form.transferMoreLimitText = this.form.transferLimitQty ? '是' : '否'
                    this.form.shareNameListText = this.form.shareNameList.join(',')
                    this.form.periodDef = JSON.parse(this.form.periodDef)
                    this.form.regionDef = this.form.regionDef.split(',')
                    this.form.venueDef = this.form.regionDef
                    if (this.form.cardType === 3) {
                        const tempArr = this.form.availableTo ? this.form.availableTo.split(',') : []
                        const availableToArr = tempArr.map((item) => {
                            let tempStr = ''
                            this.availabelBusinessList.forEach((cItem) => {
                                if (item - 0 === cItem.value) {
                                    tempStr = cItem.label
                                }
                            })
                            return tempStr
                        })
                        this.form.availableToText = availableToArr.length > 0 ? availableToArr.join(',') : '-'
                    }

                }
            })
        },
        closeDialog() {
            this.$emit('closeDialog', false)
            this.showFlag = false
        },
    },
}
</script>
<style lang='scss' scoped>
.detail-form{
    .el-form-item{ margin-bottom: 0!important;}
    .t-item{ color: #333333; line-height: 24px; padding-top: 8px;
        .card-fm{ height: 120px; width:150px;}
    }
    .t-status{ border-radius: 15px; padding: 1px 4px 0; height: 28px; line-height: 28px; text-align: center; display: inline-block;
        span{ padding: 0 10px; font-size: 14px; border: 1px solid #ffffff; border-radius: 15px; color: #ffffff;}
    }
    .t-status.status2{ background: #28B779;}
    .t-status.status1{ background: #001E4E;}
    .t-status.status3{ background: #D7D7D7;}
    .t-status.status4{ background: #F56C6C;}
    .line{width: 100%;height: 1px; background: #eeeeee; margin: 10px 0;}
}
.pb-20{ padding-bottom: 20px;}
</style>
