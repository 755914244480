<template>
    <div class="dialog-container">
        <el-dialog
            title="会员卡充值"
            :visible.sync="showFlag"
            :before-close="closeDialog"
            center
            :close-on-click-modal="false"
            :append-to-body="true"
            top="10vh"
            width="600px"
            >
            <el-form ref="form" :model="form" class="cancel-form" :rules="rules" label-width="100px">
                <el-form-item label="会员姓名">
                    <el-input disabled v-model="cardInfo.userName"></el-input>
                </el-form-item>
                <el-form-item label="会员卡名称">
                    <el-input disabled v-model="cardInfo.cardName"></el-input>
                </el-form-item>
                <el-form-item label="充值余额">
                    <el-input-number
                    disabled
                    v-model="cardInfo.availableMoney"
                    class="width-300"
                    :controls="false"
                    :precision="2"
                    :min="0"
                    :max="9999999"
                    ></el-input-number>
                </el-form-item>
                <el-form-item label="赠送余额">
                    <el-input-number
                    disabled
                    v-model="cardInfo.giveMoney"
                    class="width-300"
                    :controls="false"
                    :precision="2"
                    :min="0"
                    :max="9999999"
                    ></el-input-number>
                </el-form-item>
                <el-form-item label="充值额度">
                    <div class="df ai">
                        <el-select v-model="rechargeValue" placeholder="请选择" @change="changeLimit" style="width:300px">
                            <el-option label="其他金额" value=""></el-option>
                            <el-option
                                v-for="item in rechargeList"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id">
                            </el-option>
                        </el-select>
                    </div>
                </el-form-item>
                <el-form-item label="充值金额" prop="rechargeMoney">
                    <div class="df ai">
                        <el-input-number
                        v-model="form.rechargeMoney"
                        class="width-300"
                        :controls="false"
                        :precision="2"
                        :min="0.01"
                        :max="9999999"
                        :disabled="rechargeValue !== ''"
                        placeholder="请输入充值金额"
                        ></el-input-number>
                        <div class="margin-left-10">元</div>
                    </div>
                </el-form-item>
                <el-form-item label="赠送金额" prop="giveMoney">
                    <div class="df ai">
                        <el-input-number
                        v-model="form.giveMoney"
                        class="width-300"
                        :controls="false"
                        :precision="2"
                        :min="0"
                        :max="9999999"
                        :disabled="rechargeValue !== ''"
                        placeholder="请输入赠送金额"
                        ></el-input-number>
                        <div class="margin-left-10">元</div>
                    </div>
                </el-form-item>
                <el-form-item label="收款方式" prop="payType">
                    <el-radio-group v-model="form.payType">
                        <el-radio-button :label="1">现金</el-radio-button>
                        <el-radio-button :label="5">微信</el-radio-button>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="收款金额:" prop="actualMoney">
                    <div class="df ai">
                        <el-input-number
                        v-model="form.actualMoney"
                        class="width-300"
                        controls-position="right"
                        :precision="2"
                        :min="0.00"
                        :max="9999999"
                        placeholder="请输入收款金额"
                        :disabled="form.payType===5  && rechargeValue !== ''"
                        ></el-input-number>
                        <div class="margin-left-10">元</div>
                    </div>
                </el-form-item>
                <!-- <el-form-item label="" prop="showOnHp">
                    <div class="t-item">
                        <el-image
                        class="card-fm"
                        fit="contain"
                        src="https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg"
                            >
                            <div slot="error" class="image-slot">
                                <i class="el-icon-picture-outline"></i>
                            </div>
                        </el-image>
                        <div>请会员微信扫码支付</div>
                    </div>
                </el-form-item> -->
            </el-form>
            <template slot="footer">
                <el-button class="footer-confirm" @click="sureEvent">{{$t('sure')}}</el-button>
                <el-button class="footer-cancel" @click="closeDialog">{{$t('cancel')}}</el-button>
            </template>
        </el-dialog>
        <el-dialog
            title="微信支付"
            :visible.sync="payDialog.show"
            center
            :close-on-click-modal="false"
            :append-to-body="true"
            width="60%">
            <div style="display:flex;width:100%">
                <img :src="payDialog.paymentQRCode" alt="" style="margin: 0 auto;">
            </div>
            <template slot="footer">
                <!-- 如果没特别需求，统一使用mixin里面的关闭方法 -->
                <el-button class="footer-cancel" @click="closePayDialog">{{$t('cancel')}}</el-button>
            </template>
        </el-dialog>
    </div>
</template>

<script>
import apis from "@/apis";
import { mapState } from "vuex";

export default {
    data() {
        return {
            rechargeValue: '',
            showFlag: false,
            cardInfo: {},
            form: {
                cardId: '',
                userId: '',
                rechargeMoney: 0.01,
                giveMoney: 0,
                payType: '',
                actualMoney: 0,
            },
            rules: {
                rechargeMoney: { required: true, message: '充值金额不能为空', trigger: ['blur', 'change'] },
                giveMoney: { required: true, message: '赠送金额不能为空', trigger: ['blur', 'change'] },
                payType: { required: true, message: '请选择收款方式', trigger: ['blur', 'change'] },
            },
            rechargeList: [],
            payDialog: {
                show: false,
                paymentQRCode: '',
            },
        };
    },
    computed: {
        ...mapState(['NAME', 'USERNAME', "serviceMemberInfo"]),
    },
    methods: {
        changeLimit(value) {
            if (value) {
                this.rechargeList.forEach((item) => {
                    if (item.id === value) {
                        this.form.giveMoney = item.giveMoney
                        this.form.rechargeMoney = item.fullMoney
                        this.form.actualMoney = item.fullMoney
                    }
                })
            } else {
                this.form.rechargeMoney = 0.01
                this.form.giveMoney = 0
                this.form.actualMoney = 0.01
            }
        },
        init() {
            if (this.$refs.form) {
                this.$refs.form.resetFields();
            }
            this.rechargeValue = ''
            this.cardInfo = {}
        },
        // 弹框打开触发
        showDialog(data) {
            this.init()
            this.form.cardId = data.id || ''
            this.form.userId = data.userId
            this.cardInfo = {
                ...data,
                userName: `${this.serviceMemberInfo.userName}`,
            }
            this.showFlag = true
            this.rechargeList = this.cardInfo.coupons
        },
        // 确定
        sureEvent() {
            this.$refs.form.validate((valid) => {
                if (valid) {
                    if (this.form.payType === 5 && this.form.actualMoney === 0) {
                        this.$message.error('微信支付金额必须大于0')
                        return
                    }
                    const postObj = {
                        ...this.form,
                        operatorName: this.NAME,
                        operatorPhone: this.USERNAME,
                        venueId: 'c4d09c07cbb54b7f8184a25d073d7703',
                        couponId: this.rechargeValue,
                    }
                    this.$http.post(apis.rechargeUserCard, postObj).then((res) => {
                        if (res.data.code === 0) {
                            if (res.data.rows && res.data.rows.paymentQRCode) {
                                this.payDialog.show = true
                                this.payDialog.paymentQRCode = res.data.rows.paymentQRCode
                                const wsServer = window.location.protocol === 'http:' ? `ws://${window.location.host}/payapi/websocket/${res.data.rows.orderNo}` : `wss://bjat.aofeijituan.com.cn/payapi/websocket/${res.data.rows.orderNo}`
                                const websocket = new WebSocket(wsServer) // 创建WebSocket对象
                                console.log(websocket.readyState) // 查看websocket当前状态
                                websocket.onmessage = (evt) => {
                                    console.log('推送数据', evt)
                                    const data1 = JSON.parse(evt.data)
                                    if (data1.type === 'pcreservate') {
                                        if (data1.code === '0') {
                                            this.closePayDialog()
                                            this.$emit('refresh')
                                            this.closeDialog();
                                            this.$message.success("支付成功");
                                        }
                                    }
                                }
                            } else {
                                this.$emit('refresh')
                                this.closeDialog();
                            }
                        }
                    })
                }
            })
        },
        closeDialog() {
            this.init()
            this.$emit('closeDialog', false)
            this.$refs.form.resetFields();
            this.showFlag = false
            this.value = ''
        },
        // 关闭微信支付弹窗
        closePayDialog() {
            this.payDialog.show = false
        },
    },
}
</script>
<style lang='scss' scoped>
.card-fm{width: 150px; height: 150px;}
</style>
