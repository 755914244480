<template>
    <div class="dialog-container">
        <el-table
            :data="tableData"
            height="300"
            style="width: 100%"
        >
            <el-table-column
            prop="checkinTime"
            align="left"
            header-align="center"
            min-width="100"
            label="签到时间"
            >
            </el-table-column>
            <el-table-column
            prop="name"
            align="center"
            width="135"
            show-overflow-tooltip
            label="签到用卡"
            >
            </el-table-column>
            <el-table-column
            align="center"
            prop="checkinType"
            min-width="120"
            show-overflow-tooltip
            label="签到方式"
            >
                <template slot-scope="scope">
                    <template v-if="scope.row.checkinType">{{ scope.row.checkinType===1? '前台代签' : '闸机签到' }}</template>
                </template>
            </el-table-column>
            <el-table-column
            align="center"
            prop="handNumber"
            min-width="130"
            show-overflow-tooltip
            label="手牌号/手牌内码"
            >
            </el-table-column>
            <el-table-column
            align="center"
            prop="lockerNumber"
            min-width="130"
            show-overflow-tooltip
            label="柜号（状态）"
            >
                <template slot-scope="scope">
                    {{ scope.row.lockerNumber }}<template v-if="scope.row.lockerStatus">{{ scope.row.lockerStatus===1? '正常' : '逾期' }}</template>
                </template>
            </el-table-column>
            <el-table-column
            align="center"
            prop="telephone"
            min-width="130"
            show-overflow-tooltip
            label="签退时间"
            >
            </el-table-column>
        </el-table>
        <div class="pageination-wrapper df ai">
            <div class="f-cell"></div>
            <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="form.pageNum"
                :page-sizes="[10, 20, 30, 40]"
                :page-size="form.pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="form.total"
            >
            </el-pagination>
        </div>
    </div>
</template>
<script>
import apis from "@/apis";
import { mapState } from "vuex";
import dictList from "@/utils/dictList.js";
import { changeInfo } from "@/utils/index";

export default {
    data() {
        return {
            tableData: [],
            form: {
                pageNum: 1,
                pageSize: 10,
                total: 0,
            },
            lockerStatusList: dictList.LOCKER_STATUS || [],
            checkInTypeList: dictList.CHECK_IN_TYPE || [],
        };
    },
    computed: {
        ...mapState(['serviceMemberInfo']),
    },
    mounted() {
        this.getList()
    },
    methods: {
        // 获取购卡记录列表
        getList() {
            const postObj = {
                ...this.form,
                userId: this.serviceMemberInfo.userId,
            }
            this.$http.post(apis.getSignInRecord, postObj).then((res) => {
                if (res.data.code === 0) {
                    this.tableData = res.data.rows.map((item) => {
                        item.checkinTypeText = changeInfo(item.checkinType, this.checkInTypeList, 'value', 'label')
                        item.lockerStatusText = changeInfo(item.lockerStatus, this.lockerStatusList, 'value', 'label')
                        return item
                    })
                    this.form.total = res.data.total || 0
                }
            })
        },
        handleSizeChange(val) {
            this.form.pageSize = val
            this.getList()
        },
        handleCurrentChange(val) {
            this.form.pageNum = val
            this.getList()
        },
    },
}
</script>
<style lang='scss' scoped>
.pageination-wrapper{ padding: 15px 0;}
</style>
